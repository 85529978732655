// extracted by mini-css-extract-plugin
export var projectOverview = "ProjectOverview-module--project-overview--16gon";
export var descriptionSection__title = "ProjectOverview-module--description-section__title--2TdBM";
export var descriptionSection__line = "ProjectOverview-module--description-section__line--2QoP_";
export var details = "ProjectOverview-module--details--3ejWY";
export var bottomOverlay = "ProjectOverview-module--bottom-overlay--1BajA";
export var projectOverviewContainer = "ProjectOverview-module--project-overview-container--17iWK";
export var fadeIn = "ProjectOverview-module--fadeIn--1OvX4";
export var descriptionSection = "ProjectOverview-module--description-section--d-gUF";
export var image = "ProjectOverview-module--image--12pnZ";
export var arrow = "ProjectOverview-module--arrow--3W3Uc";
export var detailsEntity = "ProjectOverview-module--details-entity--3kL7Q";
export var detailsEntityTitle = "ProjectOverview-module--details-entity-title--2IMMx";
export var detailsEntityText = "ProjectOverview-module--details-entity-text--1YPxE";