import React from 'react'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import cx from 'clsx'

import * as styles from './OfferSection.module.scss'

const OfferSection = ({ smallIcon, title, children, icon: Icon, className }) => (
  <div className={cx([className || styles.offerSection])}>
    <VisibilitySensorOnce partialVisibility minTopValue={100}>
      {({ isVisible }) => (
        <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }} config={{ mass: 3 }}>
          {({ opacity, scale }) => (
            <i
              style={{ opacity, transform: `scale(${scale})` }}
              className={cx([styles.offerSection__icon, smallIcon && styles.offerSection__iconSmall])}
            >
              <Icon />
            </i>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
    <div>
      <VisibilitySensorOnce>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <h3 style={{ opacity }} className={styles.offerSection__contentHeading}>
                {title}
              </h3>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
      <VisibilitySensorOnce partialVisibility minTopValue={100}>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <p style={{ opacity }} className={styles.offerSection__content}>
                {children}
              </p>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
    </div>
  </div>
)

export { OfferSection }
