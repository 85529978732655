import React from 'react'
import { graphql } from 'gatsby'
import { VisibilitySensorOnce } from '../components/VisibilitySensorOnce'
import { Spring } from 'react-spring/renderprops'
import { animated, useSpring } from 'react-spring'

import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { withBreakpoints } from '../hoc/withBreakpoints'
import { OfferSection } from '../components/OfferSection'
import particle1 from '../images/particle-01.svg'
import particle2 from '../images/particle-02.svg'
import particle3 from '../images/particle-03.svg'
import particle4 from '../images/particle-04.svg'
import particle5 from '../images/particle-05.svg'
import particle6 from '../images/particle-06.svg'
import particle7 from '../images/particle-07.svg'
import particle8 from '../images/particle-08.svg'
import { Icon360 } from '../components/Icons/360'
import { Brain } from '../components/Icons/Brain'
import { Box } from '../components/Icons/Box'
import { Container } from '../components/Container'
import { HeroBanner } from '../components/HeroBanner'
import { Section, SectionHeader, SectionParagraph } from '../components/Section'
import { Button } from '../components/Button'
import { routes } from '../URLs'
import { ProjectOverview } from '../components/ProjectOverview'
import { Image } from '../components/Image'

import * as styles from './index-page.module.scss'
import { normalizePhoneNumber, prettifyPhoneNumber } from '../utils'

const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 30}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6 - 25}px,${y / 6 - 20}px,0)`
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`
const trans5 = (x, y) => `translate3d(${x / 9 + 15}px,${y / 9 - 15}px,0)`
const trans6 = (x, y) => `translate3d(${x / 5 - 10}px,${y / 5 + 15}px,0)`
const trans7 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 + 35}px,0)`
const trans8 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 + 35}px,0)`
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

const IndexPage = ({
  isMobile,
  data: {
    markdownRemark: { frontmatter },
    markdownRemarkOferta: { frontmatter: frontmatterOferta },
    markdownRemarkKontakt: { frontmatter: frontmatterKontakt },
    allMarkdownRemark,
  },
}) => {
  const featuredProjects = allMarkdownRemark.edges
    .filter(
      ({
        node: {
          fields: { slug: projectUrl },
        },
      }) => frontmatter.projects.items.some((projectSlug) => `/portfolio/${projectSlug}/` === projectUrl)
    )
    .map(
      ({
        node: {
          fields: { slug: projectUrl },
          frontmatter: project,
        },
      }) => ({
        ...project,
        url: projectUrl,
      })
    )

  const [particlePosition, setParticlePosition] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    <Layout>
      <Seo title="Home" description={frontmatter.seoDescription} keywords={frontmatter.seoKeywords} />
      <HeroBanner header={frontmatter.title} big>
        {frontmatter.subtitle}
      </HeroBanner>
      <Container className={styles.homeSectionAboutUsWrapper}>
        <Section className={styles.homeSectionAboutUs}>
          <SectionHeader subheader="Kim jesteśmy">O NAS</SectionHeader>
          <SectionParagraph>{frontmatter.aboutUs.text}</SectionParagraph>
        </Section>
        <div className={styles.homeSectionAboutUs__imageWrapper}>
          <Image
            className={styles.homeSectionAboutUs__image}
            alt="O nas"
            src={frontmatter.aboutUs.image}
            responsive
            dpr="auto"
            width="auto"
            crop="scale"
          />
        </div>
      </Container>
      <div className={styles.homeSection2Wrapper}>
        <Container>
          <VisibilitySensorOnce>
            {({ isVisible }) => (
              <Spring to={{ opacity: isVisible ? 1 : 0 }}>
                {({ opacity }) => (
                  <div style={{ opacity }} className={styles.homeSection2__caption}>
                    <span>Weronika Jaworska</span>
                    <span>/ Wu.Tektura</span>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensorOnce>
          <Button seeMore href={routes.oNas} />
          <Section>
            <SectionHeader subheader="Co oferujemy">OFERTA</SectionHeader>
            <div className={styles.homeSectionOffer__textBox}>
              <SectionParagraph className={styles.homeSectionOffer__text}>{frontmatter.offer.text1}</SectionParagraph>
              <SectionParagraph className={styles.homeSectionOffer__text}>{frontmatter.offer.text2}</SectionParagraph>
            </div>
          </Section>
          <div className={styles.homeSectionOffer__items}>
            <OfferSection
              smallIcon
              className={styles.homeSectionOffer__item}
              title={frontmatterOferta.title360}
              icon={Icon360}
            >
              {frontmatter.offer.textRender360}
            </OfferSection>
            <OfferSection className={styles.homeSectionOffer__item} title={frontmatterOferta.titleBrain} icon={Brain}>
              {frontmatter.offer.textPsychology}
            </OfferSection>
            <OfferSection className={styles.homeSectionOffer__item} title={frontmatterOferta.titleHexagon} icon={Box}>
              {frontmatter.offer.text3DProjection}
            </OfferSection>
          </div>
        </Container>
      </div>

      <div className={styles.homeSectionProjects}>
        <Container>
          <Section dark>
            <SectionHeader subheader="Jak projektujemy">PORTFOLIO</SectionHeader>
            <div className={styles.homeSectionProjects__textBox}>
              <SectionParagraph className={styles.homeSectionProjects__text}>
                {frontmatter.projects.text1}
              </SectionParagraph>
              <SectionParagraph className={styles.homeSectionProjects__text}>
                {frontmatter.projects.text2}
              </SectionParagraph>
            </div>
          </Section>
        </Container>
        <div className={styles.homeSectionProjectsList}>
          {featuredProjects.map((project) => (
            <ProjectOverview
              key={project.url}
              imageSrc={project.featuredImage}
              url={project.url}
              title={project.title}
              city={project.city}
              kind={project.kind}
              area={project.area}
              budget={project.budget}
            />
          ))}
        </div>
        <Button plain href={routes.portfolio} className={styles.homeSectionProjects__showMore}>
          Zobacz więcej
        </Button>
      </div>
      <div
        className={styles.homeSectionContact}
        onMouseMove={({ clientX: x, clientY: y }) => !isMobile && setParticlePosition({ xy: calc(x, y) })}
      >
        <div className={styles.homeSectionContact__overlay} />
        <Container className={styles.homeSectionContact__container}>
          <Section dark>
            <SectionHeader subheader="Napisz do nas">KONTAKT</SectionHeader>
            <div className={styles.homeSectionContact__textBox} />
          </Section>
          <div className={styles.homeSectionContact__content}>
            <VisibilitySensorOnce>
              {({ isVisible }) => (
                <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}>
                  {({ opacity, scale }) => (
                    <a
                      style={{ opacity, transform: `scale(${scale})` }}
                      className={styles.homeSectionContact__phoneNumber}
                      href={`tel:${normalizePhoneNumber(frontmatterKontakt.phoneNumbers[0].phoneNumber)}`}
                    >
                      {prettifyPhoneNumber(frontmatterKontakt.phoneNumbers[0].phoneNumber)}
                    </a>
                  )}
                </Spring>
              )}
            </VisibilitySensorOnce>
            <VisibilitySensorOnce>
              {({ isVisible }) => (
                <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}>
                  {({ opacity, scale }) => (
                    <a
                      style={{ opacity, transform: `scale(${scale})` }}
                      className={styles.homeSectionContact__email}
                      href={`mailto:${frontmatterKontakt.email}`}
                    >
                      {frontmatterKontakt.email}
                    </a>
                  )}
                </Spring>
              )}
            </VisibilitySensorOnce>
            <Button href="/kontakt" className={styles.homeSectionContact__button}>
              Napisz do nas
            </Button>
            {!isMobile && (
              <div className={styles.homeSectionContact__particles}>
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans1) }}
                  src={particle1}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans2) }}
                  src={particle2}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans3) }}
                  src={particle3}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans4) }}
                  src={particle4}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans5) }}
                  src={particle5}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans6) }}
                  src={particle6}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans7) }}
                  src={particle7}
                />
                <animated.img
                  className={styles.homeSectionContact__particle}
                  style={{ transform: particlePosition.xy.interpolate(trans8) }}
                  src={particle8}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default withBreakpoints(
  {
    mobile: {
      isMobile: true,
    },
    fallback: {
      isMobile: false,
    },
  },
  IndexPage
)

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoDescription
        seoKeywords
        title
        subtitle
        aboutUs {
          title
          text
          image
        }
        offer {
          title
          text1
          text2
          textRender360
          textPsychology
          text3DProjection
        }
        projects {
          title
          text1
          text2
          items
        }
      }
    }
    markdownRemarkOferta: markdownRemark(frontmatter: { templateKey: { eq: "oferta-page" } }) {
      frontmatter {
        title360
        titleBrain
        titleHexagon
      }
    }
    markdownRemarkKontakt: markdownRemark(frontmatter: { templateKey: { eq: "kontakt-page" } }) {
      frontmatter {
        phoneNumbers {
          phoneNumber
        }
        email
      }
    }
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "portfolio-project-page" } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            featuredImage
            title
            city
            kind
            area
            budget
          }
        }
      }
    }
  }
`
