import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Spring } from 'react-spring/renderprops'
import { easeQuadInOut } from 'd3-ease'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import cx from 'clsx'
import { SectionDarkContext } from './Section'

import * as styles from './SectionHeader.module.scss'

const SectionHeader = ({ subheader, children }) => {
  const dark = useContext(SectionDarkContext)

  return (
    <header className={cx([styles.sectionHeaderWrapper, dark && styles.sectionHeaderWrapperDark])}>
      <VisibilitySensorOnce partialVisibility="bottom" minTopValue={100}>
        {({ isVisible }) => (
          <>
            <Spring to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : 40 }}>
              {({ opacity, translate }) => (
                <h1
                  style={{ opacity, transform: `translateX(${translate}px)` }}
                  className={cx([styles.sectionHeader, translate < 30 && styles.sectionHeaderLine])}
                >
                  {children}
                </h1>
              )}
            </Spring>
            <Spring delay={200} config={{ easing: easeQuadInOut, duration: 300 }} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <h2 style={{ opacity }} className={styles.sectionSubheader}>
                  {subheader}
                </h2>
              )}
            </Spring>
          </>
        )}
      </VisibilitySensorOnce>
    </header>
  )
}

SectionHeader.propTypes = {
  subheader: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export { SectionHeader }
