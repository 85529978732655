import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { Spring } from 'react-spring/renderprops'
import { easeQuadInOut } from 'd3-ease'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'

import { SectionDarkContext } from './Section'

import * as styles from './SectionParagraph.module.scss'

const SectionParagraph = ({ children, className }) => {
  const dark = useContext(SectionDarkContext)
  return (
    <VisibilitySensorOnce partialVisibility minTopValue={50}>
      {({ isVisible }) => (
        <Spring ease={easeQuadInOut} to={{ opacity: isVisible ? 1 : 0 }}>
          {({ opacity }) => (
            <p
              style={{ opacity }}
              className={cx([styles.sectionParagraph, dark && styles.sectionParagraphDark, className])}
            >
              {children}
            </p>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
  )
}

SectionParagraph.propTypes = {
  children: PropTypes.string.isRequired,
}

export { SectionParagraph }
