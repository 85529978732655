import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Image } from '../Image'
import { Spring } from 'react-spring/renderprops'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import { Line } from '../Line'
import { ArrowIcon } from '../Icons/Arrow'
import { withBreakpoints } from '../../hoc/withBreakpoints'

import * as styles from './ProjectOverview.module.scss'

const ProjectOverview = ({ url, imageSrc, title, city, kind, area, budget, showArrow, showDetails }) => (
  <VisibilitySensorOnce partialVisibility minTopValue={100}>
    {({ isVisible }) => (
      <Spring to={{ opacity: isVisible ? 1 : 0 }}>
        {({ opacity }) => (
          <TransitionLink
            style={{ opacity }}
            exit={{
              length: 0.6,
            }}
            entry={{ delay: 0.4, length: 0.1 }}
            to={url}
            className={styles.projectOverview}
          >
            <div className={styles.projectOverviewContainer}>
              <div className={styles.bottomOverlay} />
              <div className={styles.descriptionSection}>
                <Line className={styles.descriptionSection__line} />
                <h3 className={styles.descriptionSection__title}> {title}</h3>
                {showArrow && <ArrowIcon turn="right" className={styles.arrow} />}
                {showDetails && (
                  <div className={styles.details}>
                    <div className={styles.detailsEntity}>
                      <h6 className={styles.detailsEntityTitle}>Lokalizacja:</h6>
                      <span className={styles.detailsEntityText}>{city}</span>
                    </div>
                    <div className={styles.detailsEntity}>
                      <h6 className={styles.detailsEntityTitle}>Rodzaj:</h6>
                      <span className={styles.detailsEntityText}>{kind}</span>
                    </div>
                    <div className={styles.detailsEntity}>
                      <h6 className={styles.detailsEntityTitle}>Powierzchnia:</h6>
                      <span className={styles.detailsEntityText}>{area}</span>
                    </div>
                    <div className={styles.detailsEntity}>
                      <h6 className={styles.detailsEntityTitle}>Budżet:</h6>
                      <span className={styles.detailsEntityText}>
                        {budget} PLN / m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <Image
                className={styles.image}
                src={imageSrc}
                alt={title}
                responsive
                dpr="auto"
                width="auto"
                crop="scale"
              />
            </div>
          </TransitionLink>
        )}
      </Spring>
    )}
  </VisibilitySensorOnce>
)

const ProjectOverviewWithBreakpoints = withBreakpoints(
  {
    mobile: {
      showArrow: true,
    },
    tablet: {
      showDetails: true,
    },
    desktop: {
      showDetails: true,
    },
  },
  ProjectOverview
)

export { ProjectOverviewWithBreakpoints }
