import React from 'react'
import cx from 'clsx'

import * as styles from './360.module.scss'

const Icon360 = ({ className, primary, ...props }) => (
  <svg
    viewBox="0 0 595.255 595.282"
    className={cx([styles.icon360, primary && styles.icon360Primary, className])}
    {...props}
  >
    <g>
      <g>
        <g>
          <path
            d="M568.131,459.915v-40.584c0-7.479-6.049-13.529-13.525-13.529c-7.475,0-13.525,6.051-13.525,13.529v0.402
				c-24.381-37.797-50.904-71.654-65.434-89.463c-4.715-5.799-13.234-6.697-19.02-1.928c-5.799,4.717-6.656,13.238-1.928,19.023
				c26.391,32.355,47.252,61.057,63.525,86.182c-7.148-2.291-14.678,1.504-17.02,8.559c-2.363,7.094,1.467,14.758,8.547,17.107
				l29.895,9.969c29.791,54.195,33.053,85.549,24.061,94.535c-9.588,9.578-44.801,6.262-112.416-34.523
				c-40.977-24.715-86.605-58.838-133.242-99.25c19.15-17.316,38.35-35.553,57.361-54.57
				c67.805-67.82,125.809-137.926,165.672-199.711v0.151c0,7.479,6.049,13.528,13.525,13.528c7.475,0,13.525-6.051,13.525-13.528
				v-40.586c0-1.269-0.527-2.405-0.865-3.595c31.07-58.502,36.32-98.589,15.566-119.338
				c-40.232-40.229-146.611,27.493-206.445,71.448c-6.023,4.413-7.316,12.881-2.893,18.906c4.398,5.998,12.865,7.293,18.9,2.894
				c97.199-71.382,156.98-88.478,171.311-74.116c8.695,8.697,6.486,38.728-24.369,94.639l-29.586,9.863
				c-7.078,2.352-10.91,10.016-8.545,17.109c2.311,6.961,9.68,10.727,16.744,8.592c-38.797,60.029-95.391,128.313-161.664,194.613
				c-19.465,19.467-39.1,38.033-58.658,55.65c-19.557-17.617-39.193-36.184-58.656-55.65
				c-66.277-66.301-122.871-134.582-161.668-194.612c7.064,2.134,14.432-1.63,16.744-8.592c2.365-7.095-1.467-14.758-8.547-17.108
				l-29.584-9.865C25.063,70.154,22.854,40.124,31.547,31.427c14.371-14.375,74.164,2.748,171.441,74.221
				c5.998,4.413,14.49,3.118,18.902-2.893c4.424-6.011,3.129-14.479-2.895-18.906C159.139,39.83,52.629-27.972,12.422,12.296
				C-8.332,33.045-3.082,73.133,27.988,131.634c-0.34,1.191-0.865,2.328-0.865,3.595v40.586c0,7.479,6.049,13.528,13.525,13.528
				s13.525-6.051,13.525-13.528v-0.151c39.861,61.785,97.867,131.889,165.672,199.711c19.012,19.018,38.211,37.254,57.361,54.57
				c-46.637,40.412-92.266,74.535-133.244,99.25c-67.678,40.811-102.877,44.049-112.414,34.523
				c-8.986-8.99-5.725-40.346,24.066-94.539l29.889-9.965c7.08-2.352,10.91-10.014,8.547-17.107
				c-2.342-7.049-9.865-10.846-17.008-8.559c16.201-25.014,36.938-53.564,63.17-85.746c4.717-5.785,3.844-14.307-1.941-19.037
				c-5.771-4.703-14.291-3.805-19.033,1.941c-14.451,17.732-40.805,51.398-65.064,89.01v-0.387c0-7.479-6.049-13.529-13.525-13.529
				c-7.475,0-13.525,6.053-13.525,13.529v40.586c0,1.453,0.549,2.773,0.992,4.119c-25.363,47.973-39.471,95.043-15.693,118.814
				c8.295,8.297,19.666,12.434,34.076,12.434c26.787,0,64.061-14.336,111.438-42.912c43.023-25.961,90.922-61.918,139.691-104.385
				c48.768,42.467,96.666,78.424,139.689,104.385c47.377,28.59,84.65,42.924,111.449,42.912c14.398,0,25.77-4.137,34.064-12.434
				c23.775-23.77,9.684-70.822-15.693-118.811C567.58,462.69,568.131,461.368,568.131,459.915z"
          />
          <path
            fill="currentColor"
            d="M162.336,175.878c0,7.479,6.051,13.529,13.529,13.529s13.529-6.051,13.529-13.529c0-7.464,6.064-13.529,13.529-13.529
				s13.529,6.064,13.529,13.529c0,7.466-6.063,13.529-13.529,13.529c-7.479,0-13.529,6.052-13.529,13.529
				c0,7.479,6.051,13.53,13.529,13.53c7.465,0,13.529,6.063,13.529,13.529c0,7.464-6.063,13.53-13.529,13.53
				c-7.463,0-13.529-6.064-13.529-13.53c0-7.478-6.051-13.529-13.529-13.529s-13.529,6.049-13.529,13.526
				c0,22.382,18.205,40.588,40.588,40.588c22.381,0,40.586-18.206,40.586-40.588c0-10.385-3.924-19.871-10.357-27.057
				c6.434-7.188,10.357-16.674,10.357-27.059c0-22.381-18.205-40.587-40.586-40.587
				C180.541,135.291,162.336,153.497,162.336,175.878z"
          />
          <path
            fill="currentColor"
            d="M392.33,270.581c22.381,0,40.588-18.206,40.588-40.588v-54.116c0-22.382-18.207-40.587-40.588-40.587
				s-40.588,18.205-40.588,40.587v54.116C351.742,252.375,369.949,270.581,392.33,270.581z M378.801,175.878
				c0-7.464,6.064-13.529,13.531-13.529c7.463,0,13.529,6.064,13.529,13.529v54.117c0,7.464-6.064,13.529-13.529,13.529
				s-13.531-6.064-13.531-13.529V175.878z"
          />
          <path
            fill="currentColor"
            d="M297.627,270.581c22.381,0,40.588-18.206,40.588-40.588c0-22.381-18.207-40.587-40.588-40.587
				c-4.156,0-8.09,0.811-11.869,1.978c3.057-12.313,12.018-29.035,38.928-29.035c7.477,0,13.529-6.051,13.529-13.529
				s-6.053-13.529-13.529-13.529c-53.51,0-67.646,44.246-67.646,67.645v27.058C257.039,252.375,275.246,270.581,297.627,270.581z
				 M297.627,216.466c7.465,0,13.529,6.063,13.529,13.529c0,7.464-6.064,13.529-13.529,13.529s-13.529-6.065-13.529-13.531
				C284.098,222.529,290.162,216.466,297.627,216.466z"
          />
          <path
            fill="currentColor"
            d="M487.033,135.291c0-14.916-12.141-27.058-27.057-27.058s-27.059,12.142-27.059,27.058
				c0,14.915,12.141,27.058,27.059,27.058C474.893,162.349,487.033,150.206,487.033,135.291z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export { Icon360 }
