import React from 'react'
import cx from 'clsx'

import * as styles from './Box.module.scss'

const Box = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.box, primary && styles.boxPrimary, className])}
    viewBox="0 0 595.3 595.3"
    fill="currentColor"
    {...props}
  >
    <path d="M249.7 538C120.6 526.9 23.2 471 23.2 408.1c0-14.7 5.3-29.2 15.6-43.2 3.8-5.2 2.8-12.4-2.4-16.3 -5.2-3.8-12.4-2.8-16.3 2.4C6.8 369.1 0 388.3 0 408.1c0 38.3 26.2 74.9 73.8 103 44.9 26.5 106.6 44.3 174 50.1 0.3 0 0.7 0 1 0 6 0 11-4.6 11.6-10.6C260.9 544.2 256.1 538.6 249.7 538z" />
    <path d="M300.5 540.1l-0.7 0c-6.4 0-11.6 5.3-11.6 11.7 0 6.4 5.2 11.6 11.6 11.6 0 0 0 0 0.1 0l0.6 0c6.4 0 11.6-5.2 11.6-11.6S307 540.1 300.5 540.1z" />
    <path d="M579.8 357.9c6.2-0.2 11.2-5.3 11.2-11.6 0-6.4-5.2-11.6-11.6-11.6H556c-6.4 0-11.6 5.2-11.6 11.6v23.4c0 6.4 5.2 11.6 11.6 11.6 3.3 0 6.3-1.4 8.4-3.6 5.1 10 7.6 20.2 7.6 30.4 0 62.9-97.4 118.7-226.5 129.9 -6.4 0.6-11.1 6.2-10.6 12.6 0.5 6.1 5.6 10.6 11.6 10.6 0.3 0 0.7 0 1 0 67.3-5.8 129.1-23.6 174-50.1 47.6-28.1 73.8-64.6 73.8-103C595.3 390.9 590.1 374 579.8 357.9z" />
    <path d="M297.6 109.4c-6.4 0-11.6 5.2-11.6 11.6v25.4c0 6.4 5.2 11.6 11.6 11.6s11.6-5.2 11.6-11.6v-25.4C309.3 114.6 304.1 109.4 297.6 109.4z" />
    <path d="M497.7 128.3L303.4 16.2c-3.6-2.1-8-2.1-11.6 0L97.5 128.3c-3.6 2.1-5.8 5.9-5.8 10.1v224.3c0 4.2 2.2 8 5.8 10.1l194.3 112.2c1.8 1 3.8 1.6 5.8 1.6s4-0.5 5.8-1.6l194.3-112.2c3.6-2.1 5.8-5.9 5.8-10.1V138.4C503.5 134.2 501.3 130.4 497.7 128.3zM286 454.7l-159.4-92 11-6.3c5.6-3.2 7.5-10.3 4.3-15.9 -3.2-5.6-10.3-7.5-15.9-4.3l-11 6.3V158.5l159.3 92.1 -20 11.5c-5.6 3.2-7.5 10.3-4.3 15.9 2.2 3.7 6.1 5.8 10.1 5.8 2 0 4-0.5 5.8-1.6l20.1-11.6V454.7zM297.6 196.2c-6.4 0-11.6 5.2-11.6 11.6v22.7l-159.4-92.1 159.4-92v13.2c0 6.4 5.2 11.6 11.6 11.6s11.6-5.2 11.6-11.6V46.4l159.4 92 -159.4 92.1v-22.7C309.3 201.4 304.1 196.2 297.6 196.2zM480.3 342.6l-11-6.3c-5.6-3.2-12.7-1.3-15.9 4.3s-1.3 12.7 4.3 15.9l11 6.3 -159.4 92V270.7l20.1 11.6c1.8 1.1 3.8 1.6 5.8 1.6 4 0 7.9-2.1 10.1-5.8 3.2-5.6 1.3-12.7-4.3-15.9l-20-11.5 159.3-92.1V342.6z" />
    <path d="M416.1 305.5l-22-12.7c-5.6-3.2-12.7-1.3-15.9 4.3 -3.2 5.6-1.3 12.7 4.3 15.9l22 12.7c1.8 1.1 3.8 1.6 5.8 1.6 4 0 7.9-2.1 10.1-5.8C423.6 315.9 421.7 308.7 416.1 305.5z" />
    <path d="M217 297.1c-3.2-5.6-10.3-7.5-15.9-4.3l-22 12.7c-5.6 3.2-7.5 10.3-4.3 15.9 2.2 3.7 6.1 5.8 10.1 5.8 2 0 4-0.5 5.8-1.6l22-12.7C218.3 309.8 220.2 302.7 217 297.1z" />
  </svg>
)

export { Box }
