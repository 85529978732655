// extracted by mini-css-extract-plugin
export var homeSectionAboutUsWrapper = "index-page-module--home-section--about-us-wrapper--3dMBU";
export var homeSectionAboutUs = "index-page-module--home-section--about-us--25WUz";
export var homeSectionAboutUs__imageWrapper = "index-page-module--home-section--about-us__image-wrapper--6vCAg";
export var homeSectionAboutUs__image = "index-page-module--home-section--about-us__image--y0gtp";
export var homeSection2Wrapper = "index-page-module--home-section-2-wrapper--1jPHq";
export var homeSection2__caption = "index-page-module--home-section-2__caption--3Mymm";
export var homeSectionOffer__textBox = "index-page-module--home-section-offer__text-box--cD9fE";
export var homeSectionOffer__text = "index-page-module--home-section-offer__text--1jN1V";
export var homeSectionOffer__items = "index-page-module--home-section-offer__items--2mX46";
export var homeSectionOffer__item = "index-page-module--home-section-offer__item--_eqGR";
export var homeSectionProjects = "index-page-module--home-section-projects--gioAt";
export var homeSectionProjects__textBox = "index-page-module--home-section-projects__text-box--_NRNP";
export var homeSectionProjects__text = "index-page-module--home-section-projects__text--S1__B";
export var homeSectionProjectsList = "index-page-module--home-section-projects-list--uaq67";
export var homeSectionProjects__showMore = "index-page-module--home-section-projects__show-more--2mdyG";
export var homeSectionContact = "index-page-module--home-section-contact--vRmND";
export var homeSectionContact__overlay = "index-page-module--home-section-contact__overlay--1V4FK";
export var homeSectionContact__container = "index-page-module--home-section-contact__container--1IyH5";
export var homeSectionContact__content = "index-page-module--home-section-contact__content--3_wCl";
export var homeSectionContact__particles = "index-page-module--home-section-contact__particles--c1gOK";
export var homeSectionContact__particle = "index-page-module--home-section-contact__particle--1y8dE";
export var homeSectionContact__button = "index-page-module--home-section-contact__button--2AydC";
export var homeSectionContact__phoneNumber = "index-page-module--home-section-contact__phone-number--1imUj";
export var homeSectionContact__email = "index-page-module--home-section-contact__email--zgJgi";